import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Constants } from "src/app/constants/constants";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.css", "../../formcommon.css"],
})
export class TableComponent {
  @Input() indexofelement?: number;
  @Output() unLoadAnElement = new EventEmitter<string>();
  @Input() formsPositionArray: any;
  @Output() passSelectedElementTodesigner = new EventEmitter<string>();
  @Input() which? = null;
  constants = Constants;
  @ViewChild("fieldLabelTable") fieldLabelTable!: ElementRef;
  @ViewChild("tableInput") tableInput?: ElementRef | undefined;
  rows: number = 0;
  cols: number = 0;
  colHead: any = [];
  rowHead: any = [];
  rowHeadActive: boolean = false;
  colHeadActive: boolean = false;
  field_label: String = "Table";

  constructor(
    private formCommonMethods: FormCommonMethodsService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );
      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 50);
    }
  }

  setAttributeChange(data: any) {
    this.rows = data.rows;
    this.cols = data.cols;
    this.colHead = data.colHead;
    this.rowHead = data.rowHead;
    this.rowHeadActive = data.rowHeadActive;
    this.colHeadActive = data.colHeadActive;
    this.field_label = data.field_label;

    if (data.required != null && data.required) {
      this.fieldLabelTable.nativeElement.innerHTML =
        data.field_label + " <span style='color:red'>*</span>";
    } else {
      this.fieldLabelTable.nativeElement.innerHTML = data.field_label;
    }

    let tableInp: any = this.tableInput?.nativeElement;
    tableInp.innerHTML = "";
    if (this.colHeadActive) {
      let tr = this.renderer.createElement("TR");
      for (
        let i = 0;
        i <
        (this.rowHead.length > 0
          ? this.colHead.length + 1
          : this.colHead.length);
        i++
      ) {
        let th = this.renderer.createElement("TH");
        if (this.rowHead.length > 0 && i == 0) {
          th.innerHTML = "";
        } else if (this.rowHead.length > 0) {
          th.innerHTML = this.colHead[i - 1];
        } else {
          th.innerHTML = this.colHead[i];
        }

        this.renderer.appendChild(tr, th);
      }
      this.renderer.appendChild(tableInp, tr);
    }

    if (data.hide != undefined && data.hide == true) {
      let table: any = document.getElementById("table-" + data.element);
      table.classList.add("d-none-custom-form");
    }

    for (let i = 0; i < this.rows; i++) {
      let tr = this.renderer.createElement("TR");
      if (this.rowHeadActive) {
        if (this.rowHead.length > 0 && this.rowHead[i] != null) {
          let th = this.renderer.createElement("TH");
          th.innerHTML = this.rowHead[i];
          this.renderer.appendChild(tr, th);
        }
      }
      for (let j = 0; j < this.cols; j++) {
        let td = this.renderer.createElement("TD");

        let inp = this.renderer.createElement("INPUT");
        inp.type = "text";
        inp.setAttribute("id", "tableInp-" + data.element + "-" + i + "-" + j);
        inp.setAttribute("class", "inputHeight inpText");
        this.renderer.appendChild(td, inp);
        this.renderer.appendChild(tr, td);
      }
      this.renderer.appendChild(tableInp, tr);
    }
  }
}
