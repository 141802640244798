import { Component, Input, Renderer2 } from "@angular/core";
@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css", "../../formcommon.css"],
})
export class PaymentComponent {
  @Input() indexofelement: any;
  @Input() formsPositionArray: any;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}
}
