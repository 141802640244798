<div class="elementCont" id="multiplechoicescont-{{ indexofelement }}">
    <div class="form-group form-group-custom-for-element">
      <div class="fontweight-700 overflowWrapAnywhere">
        <label for="" id="multiplechoicesLabel-{{ indexofelement }}" class="inpLabel" #multiplechoicesLabel>Checkbox</label>
      </div>
  
      <div id="multiplechoices-option-{{ indexofelement }}" class="mrgt-5 overflowWrapAnywhere" #multiplechoicesInp>
        <div class="mrgt-5 overflowWrapAnywhere">
          <input type="checkbox" value="" name="a-{{ indexofelement }}" />
          <label for="" class="mrgl-5 inpText">First</label>
        </div>
        <div class="mrgt-5 overflowWrapAnywhere">
          <input type="checkbox" value="" name="a-{{ indexofelement }}" />
          <label for="" class="mrgl-5 inpText">Second</label>
        </div>
      </div> 
      <div
        class="error-msg overflowWrapAnywhere"
        id="error-{{ indexofelement }}"
        #multiplechoicesError
      ></div>
    </div>
  

  </div>
  
