import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-date-selector",
  templateUrl: "./date-selector.component.html",
  styleUrls: [
    "./date-selector.component.css",
    "../../formcommon.css",
    "../../../../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css",
  ],
})
export class DateSelectorComponent {
  @Input() indexofelement: any;
  @Input() formsPositionArray: any;

  @ViewChild("dateLabel") dateLabel!: ElementRef;
  @ViewChild("dateInp") dateInp!: ElementRef;
  @ViewChild("dateError") dateError!: ElementRef;

  error: any = "";

  constructor(private formCommonMethods: FormCommonMethodsService) {}

  ngOnInit(): void {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );
      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 50);
    }
  }

  keydown(event: any, index: any) {
    var finalReturn: boolean = false;
    if (event.key == "/" || (event.key >= 0 && event.key <= 9)) {
      finalReturn = true;
    }
    console.log(finalReturn);
    return false;
  }

  setAttributeChange(data?: any) {
    this.error = this.dateError.nativeElement;
    var dateLabel: any = this.dateLabel.nativeElement;
    var dateInp: any = this.dateInp.nativeElement;
    if (data.field_label != null) {
      dateLabel.innerHTML = data.field_label;
    }

    if (data.required != null && data.required) {
      dateLabel.innerHTML += " <span style='color:red'>*</span>";
    } else {
      dateLabel.innerHTML = data.field_label;
    }

    if (data.from != null) {
      dateInp.setAttribute("min", data.from);
    }

    if (data.from != null) {
      dateInp.setAttribute("max", data.to);
    }
    if (data.hide != undefined && data.hide == true) {
      let datecont: any = document.getElementById("datecont-" + data.element);
      datecont.classList.add("d-none-custom-form");
    }
  }
}
