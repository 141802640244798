<div class="elementCont" id="datecont-{{indexofelement}}">
    <div class="form-group form-group-custom-for-element">
        <div class="overflowWrapAnywhere">
            <label for="" id="dateLabel-{{indexofelement}}" class="inpLabel" #dateLabel>Date</label>
        </div>
        <div style="display:flex;">
            
            <input id="dateInp-{{indexofelement}}" [matDatepicker]="myDatepicker" (keydown)="keydown($event,indexofelement)" class="form-control form-control-custom-for-element inpText" #dateInp>
            <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker></mat-datepicker>
        </div>
        
        <div class="mrgt-5">
        <div  class="error-msg overflowWrapAnywhere" id="error-{{indexofelement}}" #dateError>
    
        </div>
        </div>
    </div>
        
</div>
    