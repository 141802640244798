<div class="elementCont" id="textboxcont-{{indexofelement}}">

    
    <div class="form-group form-group-custom-for-element">
        <div class="overflowWrapAnywhere">
        
            <label for="" id="textareaLabel-{{indexofelement}}" class="inpLabel" #textareaLabel>Textarea</label>
        </div>
        
        <div class="mrgt-5">
            <textarea id="textarea-{{indexofelement}}" (keydown)="keydown($event,indexofelement)" class="form-control form-control-custom-for-element textarea-custom-form inpText" #textareaInp></textarea>
            <div class="error-msg overflowWrapAnywhere" id="error-{{indexofelement}}" #textareaError>

            </div> 
        </div>
    </div>

</div>




