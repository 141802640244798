import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {Constants} from "../constants/constants";

@Injectable({
  providedIn: "root",
})
export class FormService {
  constructor(private http: HttpClient) {}

  login(authorizationData: any) {
    var body = new HttpParams().set("grant_type", "client_credentials");

    const headers = new HttpHeaders()
      .set("content-type", "application/x-www-form-urlencoded")
      .set("Authorization", authorizationData)
      .set("X-LOGIN-APP-TYPE", "WIDGET");

    return this.http.post(Constants.BASE_API_URL + "oauth/token", body, {
      headers,
    });
  }

  getClientDetails(clientAlias: String) {
    const headers = new HttpHeaders()
      .set("content-type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + this.getToken());

    return this.http.get(Constants.BASE_API_URL + "v1/client/" + clientAlias, {
      headers,
    });
  }

  getToken() {
    const loginDetails: any = sessionStorage.getItem("loginDetails");
    return loginDetails ? JSON.parse(loginDetails).access_token : null;
  }

  getFormFromId(id: any) {
    const headers = new HttpHeaders()
      .set("content-type", "application/x-www-form-urlencoded")
      .set("Authorization", "Bearer " + this.getToken());

    return this.http.get(Constants.BASE_API_URL + "v1/api/client/forms/" + id, {
      headers,
    });
  }

  getConfiguration() {
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Authorization", "Bearer " + this.getToken());

    var params = new HttpParams()
      .set("appTypeCode", "WIDGET")
      .set("category", "ABOUT_US");

    return this.http.get(Constants.BASE_API_URL + "v1/api/client/configurations", {
      headers,
      params,
    });
  }

  submitFormData(body: any, formId: any, token: any) {
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Authorization", "Bearer " + this.getToken())
      .set("X-RECAPTCHA-TOKEN", token);

    return this.http.post(
        Constants.BASE_API_URL + "v1/api/client/forms/" + formId + "/submit",
      body,
      { headers }
    );
  }

  getWidgetConfigurations() {
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("Authorization", "Bearer " + this.getToken());

    return this.http.get(
      Constants.BASE_API_URL + "v1/client/configurations?appTypeCode=FORMS_APP",
      { headers }
    );
  }

  addRecaptchaScript(key?: any) {
    try {
      const script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.src = `https://www.google.com/recaptcha/api.js?render=${key}`;
      document.head.appendChild(script);
    } catch (error) {
      console.error(error);
    }
  }
}
