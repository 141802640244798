import { Injectable } from "@angular/core";
import { Constants } from "../constants/constants";

@Injectable({
  providedIn: "root",
})
export class FormCommonMethodsService {
  constructor() {}

  public checkIfOnlyNumber(e: any): boolean {
    if (e.which > 31 && (e.which < 48 || e.which > 57)) {
      return false;
    }
    return true;
  }

  public checkIfOnlyCharacter(e: any): boolean {
    if (
      !(
        e.which > 31 &&
        (e.which < 65 || e.which > 90) &&
        (e.which < 97 || e.which > 122)
      )
    ) {
      return true;
    }
    return false;
  }

  public checkIfOnlyEmail(div: any): boolean {
    var pattern = new RegExp(Constants.EMAIL_VALIDATOR_REGEX);
    if (pattern.test(div)) {
      return true;
    }
    return false;
  }

  public createBluePrint(rawJson: any): any {
    var temp = 0;
    var jsonStruct: any = [];
    for (var i = 0; i < rawJson.length; i++) {
      if (rawJson[i].shrink != null && rawJson[i].shrink) {
        var arr: any = [];
        arr[0] = rawJson[i];

        if (
          (rawJson[i + 1].newline == null || !rawJson[i + 1].newline) &&
          rawJson[i + 1] != null &&
          rawJson[i + 1].shrink != null &&
          rawJson[i + 1].shrink == true
        ) {
          arr[arr.length] = rawJson[i + 1];
          i += 1;
        }

        if (
          (rawJson[i + 1].newline == null || !rawJson[i + 1].newline) &&
          rawJson[i + 1] != null &&
          rawJson[i + 1].shrink != null &&
          rawJson[i + 1].shrink == true
        ) {
          arr[arr.length] = rawJson[i + 1];
          i += 1;
        }

        jsonStruct[temp] = arr;
      } else {
        jsonStruct[temp] = rawJson[i];
      }

      temp++;
    }

    return jsonStruct;
  }

  public checkIfInJsonArray(arr: any, a: any): Number {
    var found: any = -1;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].element == a) {
        found = i;
        break;
      }
    }
    return found;
  }

  public formularReader(arr: any, json: any) {
    var ans = "";
    for (var i = 0; i < arr.forumlaArr.length; i++) {
      if (arr.forumlaArr[i].name == "start_parentheses") {
        ans += "(";
      } else if (arr.forumlaArr[i].name == "end_parentheses") {
        ans += ")";
      } else if (arr.forumlaArr[i].name == "id") {
        let checkType: any = this.checkIfInJsonArray(
          json,
          arr.forumlaArr[i].id
        );
        if (checkType != -1) {
          let inp: any = null;

          if (json[checkType].elementType == Constants.SELECTBOX) {
            inp = document.getElementById("selectBox-" + arr.forumlaArr[i].id);
          } else if (json[checkType].elementType == Constants.RADIO) {
            inp = document.querySelector(
              'input[name="name-' + arr.forumlaArr[i].id + '"]:checked'
            );
          } else {
            inp = document.getElementById("textboxInp-" + arr.forumlaArr[i].id);
          }
          if (inp != null) {
            ans += inp.value.trim() == "" ? 0 : inp.value;
          } else {
            return;
          }
        } else {
          return;
        }
      } else if (arr.forumlaArr[i].name == "value") {
        ans += arr.forumlaArr[i].value;
      } else if (arr.forumlaArr[i].name == "operation") {
        ans += arr.forumlaArr[i].operation;
      }
    }
    let checkType: any = this.checkIfInJsonArray(json, arr.ans_id);
    if (checkType != -1) {
      let ansinp: any = null;
      if (json[checkType].elementType == Constants.SELECTBOX) {
        ansinp = document.getElementById("selectBox-" + arr.ans_id);
      } else if (json[checkType].elementType == Constants.TEXTBOX) {
        ansinp = document.getElementById("textboxInp-" + arr.ans_id);
      }
      try {
        let generate_ans: number = eval(ans);
        if (generate_ans != null) {
          if (json[checkType].elementType == Constants.RADIO) {
            let radioInps: any = document.getElementsByName(
              "name-" + arr.ans_id
            );
            let ifChecked: boolean = false;
            for (let l = 0; l < radioInps.length; l++) {
              if (radioInps[l].value == generate_ans) {
                radioInps[l].checked = true;
                ifChecked = true;
              }
            }
            if (!ifChecked) {
              for (let l = 0; l < radioInps.length; l++) {
                radioInps[l].checked = false;
              }
            }
          } else {
            ansinp.value = generate_ans;
          }
        }
      } catch (err) {}
    }
  }

  loadConditions(
    conditions: any,
    condition: any,
    formsPositionArray: any,
    index: any,
    event: any,
    i: any,
    constants: any,
    renderer: any
  ) {
    var cond: any = 0;
    if (condition.state == "IS EQUAL TO") {
      cond = condition.value.toLowerCase() == event.target.value.toLowerCase();
    } else if (condition.state == "IS NOT EQUAL TO") {
      cond = condition.value.toLowerCase() != event.target.value.toLowerCase();
    } else if (condition.state == "CHANGES") {
      cond = true;
    }

    var elemId: any = "";
    if (formsPositionArray[index].elementType == constants.TEXTBOX) {
      elemId =
        condition.action == "HIDE" || condition.action == "SHOW"
          ? "textboxcont"
          : "textboxInp";
    } else if (formsPositionArray[index].elementType == constants.SELECTBOX) {
      elemId =
        condition.action == "HIDE" || condition.action == "SHOW"
          ? "textboxcont"
          : "selectBox";
    } else if (formsPositionArray[index].elementType == constants.TABLE) {
      elemId =
        condition.action == "HIDE" || condition.action == "SHOW"
          ? "table"
          : "tableInp";
    } else if (
      formsPositionArray[index].elementType == constants.DATESELECTOR
    ) {
      elemId = "datecont";
    } else if (formsPositionArray[index].elementType == constants.SEPARATOR) {
      elemId = "separator";
    } else if (formsPositionArray[index].elementType == constants.RADIO) {
      elemId = "radiocont";
    } else if (
      formsPositionArray[index].elementType == constants.MULTIPLECHOICES
    ) {
      elemId = "multiplechoicescont";
    } else {
      elemId = "textboxcont";
    }

    var emptyTheArea = true;
    for (var j = 0; j < conditions.length; j++) {
      if (
        condition.state == "IS EQUAL TO" &&
        conditions[j].value == event.target.value
      ) {
        emptyTheArea = false;
      } else if (
        condition.state == "IS NOT EQUAL TO" &&
        conditions[j].value != event.target.value
      ) {
        emptyTheArea = false;
      }
    }

    if (cond) {
      if (
        condition.action != "HIDE" &&
        condition.action != "SHOW" &&
        formsPositionArray[index].elementType == constants.RADIO
      ) {
        var elem: any = document.getElementsByName(
          "name-" + condition.actionField
        );
        for (var j = 0; j < elem.length; j++) {
          if (condition.value == elem[i].value) {
            elem[i].checked = true;
          }
        }
      } else {
        var elem: any = document.getElementById(
          elemId + "-" + condition.actionField
        );
        if (condition.action == "COPY VALUE") {
          elem.value = event.target.value;
        } else if (condition.action == "SET A VALUE") {
          elem.value = condition.actionValue;
        } else if (condition.action == constants.DYNAMIC_ROW_IN_TABLE) {
          //elem.innerHTML = '';
          let children: number = elem.children.length;
          if (
            formsPositionArray[index].colHead != null &&
            formsPositionArray[index].colHead.length > 0
          ) {
            if (children == 0) {
              let tr = document.createElement("TR");

              for (
                let l = elem.children.length;
                l < formsPositionArray[index].colHead.length;
                l++
              ) {
                let td = document.createElement("TD");
                td.innerHTML = formsPositionArray[index].colHead[l];
                td.setAttribute(
                  "style",
                  "border:1px solid #ced4da;text-align:center;color:black;font-weight:bold;"
                );
                tr.appendChild(td);
              }
              elem.appendChild(tr);
            }
            children--;
          }

          if (children < event.target.value) {
            for (let k = children; k < event.target.value; k++) {
              let tr = document.createElement("TR");

              for (let l = 0; l < formsPositionArray[index].cols; l++) {
                let td = document.createElement("TD");
                td.setAttribute("style", "border:1px solid #ced4da;");
                let inp: any = document.createElement("INPUT");
                inp.type = "text";
                inp.setAttribute(
                  "id",
                  "tableInp-" + condition.actionField + "-" + k + "-" + l
                );
                inp.setAttribute(
                  "style",
                  "width:100%;height: 30px;padding: 10px;border:0px;"
                );

                td.appendChild(inp);
                tr.appendChild(td);
              }

              elem.appendChild(tr);
            }
          } else if (children >= event.target.value) {
            let decreaseChild: number = event.target.value;
            if (
              formsPositionArray[index].colHead == null ||
              formsPositionArray[index].colHead.length == 0
            ) {
              children--;
              decreaseChild--;
            }

            for (let k = children; k > decreaseChild; --k) {
              elem.children[k].remove();
            }
          }
        } else if (condition.action == "HIDE") {
          elem.classList.add("d-none-custom-form");
        } else if (condition.action == "SHOW") {
          elem.classList.remove("d-none-custom-form");
        }
      }
    } else {
      //check value is set for other selection
      if (formsPositionArray[index].elementType == constants.RADIO) {
        var elem: any = document.getElementsByName(
          "name-" + condition.actionField
        );
        for (var j = 0; j < elem.length; j++) {
          if (elem[j].value != undefined && condition.value == elem[j].value) {
            elem[j].checked = false;
          }
        }
        if (condition.action == "HIDE" || condition.action == "SHOW") {
          var elem: any = document.getElementById(
            elemId + "-" + condition.actionField
          );

          if (condition.action != "HIDE") {
            elem.classList.add("d-none-custom-form");
          } else if (condition.action != "SHOW") {
            elem.classList.remove("d-none-custom-form");
          }
        }
      } else if (
        emptyTheArea &&
        !(condition.action == "HIDE" || condition.action == "SHOW")
      ) {
        var elem: any = document.getElementById(
          elemId + "-" + condition.actionField
        );

        if (condition.action == constants.DYNAMIC_ROW_IN_TABLE) {
          elem.innerHTML = "";

          if (
            formsPositionArray[index].colHead != null &&
            formsPositionArray[index].colHead.length > 0
          ) {
            let tr = document.createElement("TR");
            for (let l = 0; l < formsPositionArray[index].colHead.length; l++) {
              let td = document.createElement("TD");
              td.innerHTML = formsPositionArray[index].colHead[l];
              td.setAttribute(
                "style",
                "border:1px solid #ced4da;text-align:center;color:black;font-weight:bold;"
              );
              tr.appendChild(td);
            }
            elem.appendChild(tr);
          }

          for (let k = 0; k < formsPositionArray[index].rows; k++) {
            let tr = document.createElement("TR");

            for (let l = 0; l < formsPositionArray[index].cols; l++) {
              let td = document.createElement("TD");
              td.setAttribute("style", "border:1px solid #ced4da;");
              let inp: any = document.createElement("INPUT");
              inp.type = "text";
              inp.setAttribute(
                "id",
                "tableInp-" + condition.actionField + "-" + k + "-" + l
              );
              inp.setAttribute(
                "style",
                "width:100%;height: 30px;padding: 10px;border:0px;"
              );

              td.appendChild(inp);
              tr.appendChild(td);
            }

            elem.appendChild(tr);
          }
        } else {
          elem.value = "";
        }
      } else {
        if (condition.action == "HIDE" || condition.action == "SHOW") {
          var elem: any = document.getElementById(
            elemId + "-" + condition.actionField
          );
          if (condition.action != "HIDE") {
            elem.classList.add("d-none-custom-form");
          } else if (condition.action != "SHOW") {
            elem.classList.remove("d-none-custom-form");
          }
        }
      }
    }
  }
}
