import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { Constants } from "src/app/constants/constants";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-radio",
  templateUrl: "./radio.component.html",
  styleUrls: ["./radio.component.css", "../../formcommon.css"],
})
export class RadioComponent {
  @Input() indexofelement: any;
  @Input() formsPositionArray: any;

  @ViewChild("radioLabel") radioLabel!: ElementRef;
  @ViewChild("radioInp") radioInp!: ElementRef;
  @ViewChild("radioError") radioError!: ElementRef;
  public conditions: any = [];

  constructor(
    private renderer: Renderer2,
    private formCommonMethods: FormCommonMethodsService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );

      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 50);
    }
  }

  setAttributeChange(data?: any) {
    var radioLabel: any = this.radioLabel.nativeElement;
    if (data.field_label != null) {
      radioLabel.innerHTML = data.field_label;
    } else {
      radioLabel.innerHTML = "";
    }

    if (data.required != null && data.required) {
      radioLabel.innerHTML += " <span style='color:red'>*</span>";
    } else {
      radioLabel.innerHTML = data.field_label;
    }

    var radioOptn: any = this.radioInp.nativeElement;
    radioOptn.innerHTML = "";

    if (data.hide != undefined && data.hide == true) {
      let radiocont: any = document.getElementById("radiocont-" + data.element);
      radiocont.classList.add("d-none-custom-form");
    }

    if (data.options != null && data.options.length > 0) {
      for (var i = 0; i < data.options.length; i++) {
        var option: any = this.renderer.createElement("DIV");

        var inp: any = this.renderer.createElement("INPUT");
        inp.setAttribute("type", "radio");
        inp.setAttribute("value", data.options[i]);
        inp.setAttribute("name", "name-" + data.element);
        inp.setAttribute("id", "id-" + data.element + "-" + i);
        if (
          data.default != null &&
          data.default != "" &&
          data.default == data.options[i]
        ) {
          inp.setAttribute("selected", "true");
          inp.checked = true;
        }
        this.renderer.appendChild(option, inp);

        var label: any = this.renderer.createElement("LABEL");
        label.setAttribute("for", "id-" + data.element + "-" + i);
        label.innerHTML = data.options[i];
        label.setAttribute("class", "mrgl-5");
        this.renderer.appendChild(option, label);

        this.renderer.appendChild(radioOptn, option);

        if (data.conditions != undefined && data.conditions.length > 0) {
          this.conditions = data.conditions;

          this.renderer.listen(inp, "change", (event) => {
            for (var i = 0; i < this.conditions.length; i++) {
              var index: any = this.formCommonMethods.checkIfInJsonArray(
                this.formsPositionArray,
                this.conditions[i].actionField
              );

              this.formCommonMethods.loadConditions(
                this.conditions,
                this.conditions[i],
                this.formsPositionArray,
                index,
                event,
                i,
                Constants,
                this.renderer
              );
            }
          });
        }
      }
    }
  }
}
