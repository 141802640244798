import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormComponent } from "./form/form.component";
import { DateSelectorComponent } from "./elements/date-selector/date-selector.component";
import { HeadingComponent } from "./elements/heading/heading.component";
import { ParagraphComponent } from "./elements/paragraph/paragraph.component";
import { RadioComponent } from "./elements/radio/radio.component";
import { SelectboxComponent } from "./elements/selectbox/selectbox.component";
import { SeparatorComponent } from "./elements/separator/separator.component";
import { TextareaComponent } from "./elements/textarea/textarea.component";
import { TextboxComponent } from "./elements/textbox/textbox.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PaymentComponent } from "./elements/payment/payment.component";
import { TableComponent } from "./elements/table/table.component";
import { UserInfoComponent } from "./elements/user-info/user-info.component";
import { AddressComponent } from "./elements/address/address.component";
import { MultipleChoicesComponent } from "./elements/multiple-choices/multiple-choices.component";
import { ImageComponent } from "./elements/image/image.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    DateSelectorComponent,
    HeadingComponent,
    ParagraphComponent,
    RadioComponent,
    SelectboxComponent,
    SeparatorComponent,
    TextareaComponent,
    TextboxComponent,
    PaymentComponent,
    TableComponent,
    UserInfoComponent,
    AddressComponent,
    MultipleChoicesComponent,
    ImageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
  ],
  providers: [MatDatepickerModule, MatNativeDateModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
