import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-separator",
  templateUrl: "./separator.component.html",
  styleUrls: ["./separator.component.css", "../../formcommon.css"],
})
export class SeparatorComponent {
  @Input() indexofelement: any;
  @Input() formsPositionArray: any;

  @ViewChild("separatorDiv") separatorDiv!: ElementRef;
  constructor(
    private renderer: Renderer2,
    private formCommonMethods: FormCommonMethodsService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );
      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 50);
    }
  }

  setAttributeChange(data: any) {
    var separator: any = this.separatorDiv.nativeElement;
    if (data.separator_height != null || data.separator_height != "") {
      separator.style.height = data.separator_height + "px";
    } else {
      separator.style.height = "1px";
    }

    if (data.separator_color != null || data.separator_color != "") {
      separator.style.background = data.separator_color;
    } else {
      separator.style.background = "#000";
    }

    if (data.hide != undefined && data.hide == true) {
      let separator: any = document.getElementById("separator-" + data.element);
      separator.classList.add("d-none-custom-form");
    }
  }
}
