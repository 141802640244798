
<div class="elementCont" id="textboxcont-{{indexofelement}}">

    <div class="form-group form-group-custom-for-element">
        <div class="overflowWrapAnywhere">
            <label for="" id="selectBoxLabel-{{indexofelement}}" class="inpLabel" #selectboxLabel>Selectbox</label>
        </div> 

        <div class="mrgt-5">
    
            <select name="" id="selectBox-{{indexofelement}}" class="select select-custom-for-element inpText" style="padding:5px;" #selectboxInp>
            </select>
            
            <div class="error-msg overflowWrapAnywhere" id="error-{{indexofelement}}" #selectboxError>

            </div>
        </div>
    </div>

</div>
