import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.css", "../../formcommon.css"],
})
export class UserInfoComponent {
  @Input() indexofelement!: number;
  @Input() formsPositionArray: any;
  public error: any = "";

  @ViewChild("userInfoheadingLabel") userInfoheadingLabel: ElementRef | any;

  @ViewChild("userInfonameLabel") userInfonameLabel: ElementRef | any;
  @ViewChild("userInfoemailLabel") userInfoemailLabel: ElementRef | any;
  @ViewChild("userInfophoneLabel") userInfophoneLabel: ElementRef | any;

  constructor(private formCommonMethods: FormCommonMethodsService) {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );
      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 50);
    }
  }

  keydown(event: any, elementId: any) {
    return true;
  }

  setAttributeChange(data?: any) {
    var userInfoheadingLabel: any = this.userInfoheadingLabel.nativeElement;

    var userInfonameLabel: any = this.userInfonameLabel.nativeElement;

    var userInfoemailLabel: any = this.userInfoemailLabel.nativeElement;

    var userInfophoneLabel: any = this.userInfophoneLabel.nativeElement;
    if (data.field_label_heading != null) {
      userInfoheadingLabel.innerHTML = data.field_label_heading;
    }
    if (data.field_label_name != null) {
      userInfonameLabel.innerHTML =
        data.field_label_name + "<span style='color: red'>*</span>";
    }
    if (data.field_label_email != null) {
      userInfoemailLabel.innerHTML =
        data.field_label_email + "<span style='color: red'>*</span>";
    }
    if (data.field_label_phone != null) {
      userInfophoneLabel.innerHTML =
        data.field_label_phone + "<span style='color: red'>*</span>";
    }
  }
}
