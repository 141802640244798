import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.css", "../../formcommon.css"],
})
export class AddressComponent {
  @Input() indexofelement: any;
  @Input() formsPositionArray: any;

  @ViewChild("addressInp1") addressInp1!: ElementRef;
  @ViewChild("addressInp2") addressInp2!: ElementRef;
  @ViewChild("addressStateInp") addressStateInp!: ElementRef;
  @ViewChild("addressCityInp") addressCityInp!: ElementRef;
  @ViewChild("addressPostalInp") addressPostalInp!: ElementRef;
  @ViewChild("addressCountryInp") addressCountryInp!: ElementRef;

  @ViewChild("addressLabel") addressLabel!: ElementRef;
  @ViewChild("addressLabel1") addressLabel1!: ElementRef;
  @ViewChild("addressLabel2") addressLabel2!: ElementRef;
  @ViewChild("addresserror") addresserror!: ElementRef;
  @ViewChild("addressDelete") addressDelete!: ElementRef;
  @ViewChild("addressElementSelected") addressElementSelected!: ElementRef;

  public error: any = "";

  constructor(private formCommonMethods: FormCommonMethodsService) {}

  ngOnInit(): void {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );
      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 50);
    }
  }

  setAttributeChange(data: any) {
    var addressLabel: any = this.addressLabel.nativeElement;
    var addressLabel1: any = this.addressLabel1.nativeElement;
    var addressLabel2: any = this.addressLabel2.nativeElement;
    this.error =
      this.addresserror != undefined ? this.addresserror.nativeElement : "";

    if (data.field_label != null) {
      addressLabel.innerHTML = data.field_label;
    }
    if (data.field_label_address1 != null) {
      addressLabel1.innerHTML = data.field_label_address1;
    }
    if (data.field_label_address2 != null) {
      addressLabel2.innerHTML = data.field_label_address2;
    }
    if (data.required != null && data.required) {
      addressLabel.innerHTML += " <span style='color:red'>*</span>";
    } else {
      addressLabel.innerHTML = data.field_label;
    }

    if (data.hide != undefined && data.hide == true) {
      let textboxcont: any = document.getElementById(
        "textboxcont-" + data.element
      );
      textboxcont.classList.add("d-none-custom-form");
    }
  }
}
