
<div class="elementCont" id="textboxcont-{{indexofelement}}">
    <div class="form-group form-group-custom-for-element">
        <div class="overflowWrapAnywhere">
            <label for="" #textboxLabel id="textboxLabel-{{indexofelement}}">Textbox</label>
        </div>
        
        <div class="mrgt-5">
            <input type="text" #textboxInp id="textboxInp-{{indexofelement}}" (keydown)="keydown($event,indexofelement)" class="form-control form-control-custom-for-element inpText">
        
            <div class="error-msg overflowWrapAnywhere"  id="error-{{indexofelement}}" #textboxerror> 
            </div>
            
        </div>

    </div>
</div>

    
    
    