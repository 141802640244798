import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-textbox",
  templateUrl: "./textbox.component.html",
  styleUrls: ["./textbox.component.css", "../../formcommon.css"],
})
export class TextboxComponent implements OnInit {
  @Input() indexofelement!: number;
  @Input() formsPositionArray: any;
  public error: any = "";

  @ViewChild("textboxInp") textbox!: ElementRef;
  @ViewChild("textboxLabel") textboxLabel!: ElementRef;
  @ViewChild("textboxerror") textboxerror!: ElementRef;

  constructor(
    private renderer: Renderer2,
    private formCommonMethods: FormCommonMethodsService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );
      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 50);
    }
  }

  loadView(e: any) {}

  setAttributeChange(data?: any) {
    this.error = this.textboxerror.nativeElement;

    var textboxLabel = this.textboxLabel.nativeElement;

    var textboxInp: any = this.textbox.nativeElement;
    if (data.field_label != null) {
      textboxLabel.innerHTML = data.field_label;
    }

    if (data.required != null && data.required) {
      textboxLabel.innerHTML += " <span style='color:red'>*</span>";
    } else {
      textboxLabel.innerHTML = data.field_label;
    }

    if (data.validation != null && data.validation == "Numeric") {
      if (
        data.min_range != null &&
        data.min_range != "" &&
        data.max_range != null &&
        data.max_range != ""
      ) {
        textboxLabel.innerHTML +=
          " (Allowed Values : " + data.min_range + " - " + data.max_range + ")";
      }
      if (
        data.min_range != null &&
        data.min_range != "" &&
        (data.max_range == null || data.max_range == "")
      ) {
        textboxLabel.innerHTML +=
          " (Allowed Minimum Value : " + data.min_range + ")";
      }

      if (
        (data.min_range == null || data.min_range == "") &&
        data.max_range != null &&
        data.max_range != ""
      ) {
        textboxLabel.innerHTML +=
          " (Allowed Maximum Value : " + data.max_range + ")";
      }
    }

    if (data.placeholder != null && data.placeholder != "") {
      textboxInp.placeholder = data.placeholder;
    } else {
      textboxInp.placeholder = "";
    }

    if (data.default != null && data.default != "") {
      textboxInp.value = data.default;
    } else {
      textboxInp.value = "";
    }

    if (data.readonly != null && data.readonly) {
      textboxInp.setAttribute("readonly", "true");
    } else {
      textboxInp.removeAttribute("readonly");
    }

    if (data.min_char != null && data.min_char != "") {
      textboxInp.setAttribute("minLength", data.min_char);
    } else {
      textboxInp.removeAttribute("minLength");
    }

    if (data.max_char != null && data.max_char != "") {
      textboxInp.setAttribute("maxLength", data.max_char);
    } else {
      textboxInp.removeAttribute("maxLength");
    }

    if (data.validation != null && data.validation != "") {
      textboxInp.setAttribute("validation", data.validation);
    } else {
      textboxInp.removeAttribute("validation");
    }

    if (data.hide != undefined && data.hide == true) {
      let textboxcont: any = document.getElementById(
        "textboxcont-" + data.element
      );
      textboxcont.classList.add("d-none-custom-form");
    }
  }

  keydown(event: any, elementId: any) {
    var char_length = event.target.value.length;
    let index: any = this.formCommonMethods.checkIfInJsonArray(
      this.formsPositionArray,
      elementId
    );
    var finalReturn: boolean = true;

    this.error = this.textboxerror.nativeElement;
    this.error.innerHTML = "";

    if (
      this.formsPositionArray[index].validation != "Numeric" &&
      "min_char" in this.formsPositionArray[index] &&
      this.formsPositionArray[index].min_char != null &&
      this.formsPositionArray[index].min_char != ""
    ) {
      if (char_length < this.formsPositionArray[index].min_char) {
        this.error.innerHTML =
          "Char length should be greater than " +
          this.formsPositionArray[index].min_char;
      }
    }

    if (
      this.formsPositionArray[index].validation != "Numeric" &&
      "max_char" in this.formsPositionArray[index] &&
      this.formsPositionArray[index].max_char != null &&
      this.formsPositionArray[index].max_char != ""
    ) {
      if (char_length >= this.formsPositionArray[index].max_char) {
        this.error.innerHTML =
          "Char length should be less than " +
          this.formsPositionArray[index].max_char;
        finalReturn = false;
      }
    }

    if (
      "validation" in this.formsPositionArray[index] &&
      this.formsPositionArray[index].validation != null &&
      this.formsPositionArray[index].validation != "" &&
      this.formsPositionArray[index].validation.toLowerCase() != "none"
    ) {
      if (
        this.formsPositionArray[index].validation.toLowerCase() == "alphabetic"
      ) {
        finalReturn = this.formCommonMethods.checkIfOnlyCharacter(event);
      } else if (
        this.formsPositionArray[index].validation.toLowerCase() == "numeric"
      ) {
        finalReturn = this.formCommonMethods.checkIfOnlyNumber(event);
      } else if (
        this.formsPositionArray[index].validation.toLowerCase() ==
        "alpha numeric"
      ) {
        finalReturn =
          this.formCommonMethods.checkIfOnlyNumber(event) ||
          this.formCommonMethods.checkIfOnlyCharacter(event);
      } else if (
        this.formsPositionArray[index].validation.toLowerCase() == "email"
      ) {
        var value: any = document.getElementById(event.target.id);
        if (!this.formCommonMethods.checkIfOnlyEmail(value.value)) {
          this.error.innerHTML = "Please enter valid email address!";
        }
      }

      if (event.which == 8 || event.which == 32) {
        finalReturn = true;
      }
    }

    return finalReturn;
  }
}
