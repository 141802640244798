
<div class="elementCont" id="textboxcont-{{ indexofelement }}">
    <div class="form-group form-group-custom-for-element">
        <div class="overflowWrapAnywhere">
        <label for="" #addressLabel class="txt-bold" id="addressLabel-{{ indexofelement }}">Address</label>

        <div class="mrgt-10">
          <label for="" #addressLabel1   id="addressLabel1-{{ indexofelement }}">Address 1</label>
        
      
  
      
        <input
          type="text"
          placeholder=""
          #addressInp1
          id="addressInp1-{{ indexofelement }}"
          class="form-control form-control-custom-for-element inpText"
        />
      </div>
        <div class="overflowWrapAnywhere mrgt-5" >
            <label for="" #addressLabel2  id="addressLabel2-{{ indexofelement }}">Address 2 (Optional)</label>
        </div>
        <div >
        <input
          type="text"
          placeholder=""
          #addressInp2
          id="addressInp2-{{ indexofelement }}"
          class="form-control form-control-custom-for-element inpText"
        />
    </div>
    
        
            
    


           <div class="flex d-flex-custom-form d-flex-custom-form-768">
    
        <div class="overflowWrapAnywhere mrgt-5 w-100-custom-form">
            <label for="" #addressLabel  id="addressLabel-{{ indexofelement }}">State</label>
        
          <input
            type="text"
            #addressStateInp
            id="addressStateInp-{{ indexofelement }}"
            class="form-control form-control-custom-for-element inpText"
            name="opn2"
          />
        </div>
    
    
        
        
          <div class="overflowWrapAnywhere mrgl-10 mrgt-5 w-100-custom-form">
                <label for="" #addressLabel   id="addressLabel-{{ indexofelement }}">City</label>
            
            
                <input
                type="text"
                placeholder=""
                #addressCityInp
                id="addressCityInp-{{ indexofelement }}"
                class="form-control form-control-custom-for-element inpText"
              
              
            />
            
          </div>
        
        
        <div class="overflowWrapAnywhere mrgl-10 mrgt-5 w-100-custom-form">
            <label for="" #addressLabel   id="addressLabel-{{ indexofelement }}">Zip/Postal</label>
        
          <input
            type="text"
            placeholder=""
            #addressPostalInp
            id="addressPostalInp-{{ indexofelement }}"
            class="form-control form-control-custom-for-element inpText"/>
        </div>
        
      </div>
      <div class="overflowWrapAnywhere mrgt-5 ">
        <label for="" #addressLabel  id="addressLabel-{{ indexofelement }}">Country</label>
        
        <input 
            type="text"
            aria-placeholder="Country"
            #addressCountryInp
            id="addressCountryInp-{{ indexofelement }}"
            class="form-control form-control-custom-for-element inpText"
            name="opn1"
            
           />
  </div>
        <div
          class="error-msg overflowWrapAnywhere"
          id="error-{{ indexofelement }}"
          #addresserror
        ></div>
      
    </div>
  </div>

  </div>
  
