import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-multiple-choices",
  templateUrl: "./multiple-choices.component.html",
  styleUrls: ["./multiple-choices.component.css", "../../formcommon.css"],
})
export class MultipleChoicesComponent {
  @Input() indexofelement: any;
  @Input() formsPositionArray: any;

  @ViewChild("multiplechoicesLabel") multiplechoicesLabel!: ElementRef;
  @ViewChild("multiplechoicesInp") multiplechoicesInp!: ElementRef;

  constructor(
    private renderer: Renderer2,
    private formCommonMethods: FormCommonMethodsService
  ) {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );

      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 50);
    }
  }

  setAttributeChange(data: any) {
    var multiplechoicesLabel: any = this.multiplechoicesLabel.nativeElement;

    if (data.field_label != null) {
      multiplechoicesLabel.innerHTML = data.field_label;
    } else {
      multiplechoicesLabel.innerHTML = "";
    }

    if (data.required != null && data.required) {
      multiplechoicesLabel.innerHTML += " <span style='color:red'>*</span>";
    } else {
      multiplechoicesLabel.innerHTML = data.field_label;
    }

    if (data.hide != undefined && data.hide == true) {
      let multiplechoicescont: any = document.getElementById(
        "multiplechoicescont-" + data.element
      );
      multiplechoicescont.classList.add("d-none-custom-form");
    }

    var multiplechoicesOptn: any = this.multiplechoicesInp.nativeElement;
    multiplechoicesOptn.innerHTML = "";

    if (data.options != null && data.options.length > 0) {
      for (var i = 0; i < data.options.length; i++) {
        var option: any = this.renderer.createElement("DIV");
        option.setAttribute("class", "mrgt-5");
        var inp: any = this.renderer.createElement("INPUT");
        inp.setAttribute("type", "checkbox");
        inp.setAttribute("value", data.options[i]);
        inp.setAttribute("name", "name-" + data.element);
        inp.setAttribute("id", "id-" + data.element + "-" + i);

        // var Color = ["blue", "black", "brown", "gold"];
        // var a = Color.indexOf("black");
        // alert(a);
        for (var k = 0; k < data.options[i]; k++) {
          //   if (data.options[i] == this.submittedValue) {
          //     inp.setAttribute('selected', 'true' );
          //     inp.checked = true;
          //   }
          // }
          //  else {
          //   if (data.default != null && data.default != '' && data.default == data.options[i]) {
          //     inp.setAttribute('selected', 'true' );
          //     inp.checked = true;
          //   }
          // }
        }
        this.renderer.appendChild(option, inp);

        var label: any = this.renderer.createElement("LABEL");
        label.setAttribute("for", "id-" + data.element + "-" + i);
        label.innerHTML = data.options[i];
        label.setAttribute("class", "mrgl-5");
        this.renderer.appendChild(option, label);

        this.renderer.appendChild(multiplechoicesOptn, option);
      }
    }
  }
}
