<div class="elementCont" id="table-{{indexofelement}}" >
  <div class="form-group form-group-custom-for-element" >
    <div class="overflowWrapAnywhere">
      <label for="" id="fieldLabelTable-{{ indexofelement }}" #fieldLabelTable>{{field_label}}</label>
    </div>
  
    <div>
        <table class="table-custom-form" #tableInput id="tableInp-{{indexofelement}}">  
            
        </table>
        <div class="error-msg overflowWrapAnywhere" id="error-{{indexofelement}}" #textareaError>

    </div>
  </div>
</div>