<div class="elementCont" id="radiocont-{{indexofelement}}">
    <div class="form-group form-group-custom-for-element">
        <div class="fontweight-700 overflowWrapAnywhere">
            <label for="" id="radioLabel-{{indexofelement}}" class="inpLabel" #radioLabel>Radio</label>
        </div>
         
        <div id="radio-option-{{indexofelement}}" class="mrgt-5 overflowWrapAnywhere" #radioInp>
            <div class="mrgt-5 overflowWrapAnywhere" >
                <input type="radio" value="" name="a-{{indexofelement}}"/>
                <label for=""  class="mrgl-5 inpText">First</label>
            </div>
            <div class="mrgt-5 overflowWrapAnywhere">
                <input type="radio" value="" name="a-{{indexofelement}}"/>
                <label for="" class="mrgl-5 inpText">Second</label>
            </div>        
        </div> 
        <div  class="error-msg overflowWrapAnywhere" id="error-{{indexofelement}}" #radioError>

        </div>
    </div>

</div>
