import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Constants } from "src/app/constants/constants";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-selectbox",
  templateUrl: "./selectbox.component.html",
  styleUrls: ["./selectbox.component.css", "../../formcommon.css"],
})
export class SelectboxComponent {
  @Input() indexofelement: any;
  @Input() formsPositionArray: any;
  @Output() changeDynamicValue = new EventEmitter<string>();

  @ViewChild("selectboxLabel") selectboxLabel!: ElementRef;
  @ViewChild("selectboxInp") selectboxInp!: ElementRef;
  @ViewChild("selectboxError") selectboxError!: ElementRef;
  public conditions: any = [];

  constructor(
    private renderer: Renderer2,
    private formCommonMethods: FormCommonMethodsService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );
      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 50);
    }
  }

  setAttributeChange(data: any) {
    var selectBoxLabel: any = this.selectboxLabel.nativeElement;

    var selectBox: any = this.selectboxInp.nativeElement;
    selectBox.innerHTML = "";
    if (data.field_label != null) {
      selectBoxLabel.innerHTML = data.field_label;
    } else {
      selectBoxLabel.innerHTML = "";
    }

    if (data.required != null && data.required) {
      selectBoxLabel.innerHTML += " <span style='color:red'>*</span>";
    } else {
      selectBoxLabel.innerHTML = data.field_label;
    }

    if (data.options != null && data.options.length > 0) {
      for (var i = 0; i < data.options.length; i++) {
        var option: any = this.renderer.createElement("OPTION");
        option.value = data.options[i];
        option.innerHTML = data.options[i];

        if (
          data.default != null &&
          data.default != "" &&
          data.default == data.options[i]
        ) {
          option.setAttribute("selected", "true");
        }

        this.renderer.appendChild(selectBox, option);
      }
    }

    if (data.hide != undefined && data.hide == true) {
      let textboxcont: any = document.getElementById(
        "textboxcont-" + data.element
      );
      textboxcont.classList.add("d-none-custom-form");
    }

    if (data.conditions != undefined && data.conditions.length > 0) {
      this.conditions = data.conditions;
      this.renderer.listen(selectBox, "change", (event) => {
        for (var i = 0; i < this.conditions.length; i++) {
          var index: any = this.formCommonMethods.checkIfInJsonArray(
            this.formsPositionArray,
            this.conditions[i].actionField
          );

          if (this.conditions[i].action == Constants.DYNAMIC_ROW_IN_TABLE) {
            this.changeDynamicValue.emit(
              JSON.stringify({
                indexofelement: this.indexofelement,
                actionField: this.conditions[i].actionField,
              })
            );
          }
          this.formCommonMethods.loadConditions(
            this.conditions,
            this.conditions[i],
            this.formsPositionArray,
            index,
            event,
            i,
            Constants,
            this.renderer
          );
        }
      });
    }
  }
}
