import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-paragraph",
  templateUrl: "./paragraph.component.html",
  styleUrls: ["./paragraph.component.css", "../../formcommon.css"],
})
export class ParagraphComponent {
  @Input() indexofelement: any;
  @Input() formsPositionArray: any;

  @ViewChild("paragraphInp") paragraphInp!: ElementRef;

  constructor(
    private renderer: Renderer2,
    private formCommonMethods: FormCommonMethodsService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );
      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 50);
    }
  }

  setAttributeChange(data: any) {
    var paragraph: any = this.paragraphInp.nativeElement;
    paragraph.innerHTML = data.paragraph;

    if (data.hide != undefined && data.hide == true) {
      let paragraph: any = document.getElementById("paragraph-" + data.element);
      paragraph.classList.add("d-none-custom-form");
    }
  }
}
