import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Constants } from "../constants/constants";
import { FormCommonMethodsService } from "../utils/form-common-methods.service";
import { FormService } from "./form.service";
import { Title } from "@angular/platform-browser";

declare let grecaptcha: any;

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css", "../formcommon.css"],
})
export class FormComponent {
  public finalSubmitForm: FormGroup;

  public finalJson: any = null;

  public jsonStruct: any = [];
  public json: any = [];
  public clientDetails: any = null;
  private formId: any = null;
  private formulas: any = [];
  private clientAlias: any = null;
  private client_secret: any = null;
  private siteConfig: any = null;
  public backgroundColor: any = "";

  @ViewChild("formError") formError?: ElementRef;

  constants = Constants;
  @ViewChild("errorOnLoad") errorOnLoad!: ElementRef;
  username: any = "";
  useremail: any = "";
  userphone: any = "";

  constructor(
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private formService: FormService,
    private formBuilder: FormBuilder,
    public formCommonMethods: FormCommonMethodsService,
    public titleService: Title
  ) {
    this.clientAlias = this.route.snapshot.paramMap.get("clientAlias");

    this.formId = this.route.snapshot.paramMap.get("formId");

    this.finalSubmitForm = this.formBuilder.group({
      name: new FormControl(""),
      email: new FormControl(""),
      phone: new FormControl(""),
    });

    var loginDetails: any = sessionStorage.getItem("loginDetails");
    if (loginDetails == null) {
      this.login();
    }

    if (loginDetails != null) {
      this.getFormFromId(this.formId);
      this.getClientDetails();
      this.captchaConfiguration();
    }
  }

  @ViewChild("mainContainer") mainContainer?: ElementRef;
  captchaConfiguration() {
    this.formService.getWidgetConfigurations().subscribe(
      (Response: any) => {
        this.siteConfig = Response;
        if (
          this.mainContainer != undefined &&
          "FORM_APP_PRIMARY_COLOR" in Response
        ) {
          this.mainContainer.nativeElement.style.backgroundColor =
            Response.FORM_APP_PRIMARY_COLOR;
        } else if (this.mainContainer != undefined) {
          this.mainContainer.nativeElement.style.backgroundColor =
            this.constants.FORMBGCOLOR;
        }
        this.formService.addRecaptchaScript(Response.GOOGLE_RECAPTCHA_KEY_SITE);
      },
      (error) => {}
    );
  }

  login() {
    let authorizationData =
      "Basic " + btoa(this.clientAlias + ":" + Constants.API_SECRET);

    this.formService.login(authorizationData).subscribe(
      (Response) => {
        var res = JSON.stringify(Response);
        sessionStorage.setItem("loginDetails", res);
        this.getFormFromId(this.formId);
        this.getClientDetails();
        this.captchaConfiguration();
      },
      (error) => {}
    );
  }

  getClientDetails() {
    this.formService.getClientDetails(this.clientAlias).subscribe(
      (Response) => {
        this.clientDetails = Response;
      },
      (error) => {}
    );
  }

  formResponse: any = "";
  getFormFromId(a: any) {
    this.formService.getFormFromId(a).subscribe(
      (Response) => {
        this.formResponse = JSON.parse(JSON.stringify(Response));
        this.json = JSON.parse(JSON.parse(JSON.stringify(Response)).formData);
        this.jsonStruct = this.formCommonMethods.createBluePrint(this.json);
        this.titleService.setTitle(this.formResponse.formName);
        if (
          "maxCapacity" in this.formResponse &&
          "submittedCapacity" in this.formResponse
        ) {
          let capacity =
            parseInt(this.formResponse.maxCapacity) -
            parseInt(this.formResponse.submittedCapacity);
          if (capacity > 0) {
            this.errorOnLoad.nativeElement.innerHTML =
              "Capacity " +
              this.formResponse.submittedCapacity +
              " of " +
              this.formResponse.maxCapacity +
              " filled";
            this.errorOnLoad.nativeElement.style.color = "black";
            this.errorOnLoad.nativeElement.style.fontSize = "16px";
          }
        }

        setTimeout(() => {
          this.loadForm();
        }, 100);
      },
      (error) => {
        if (error.status == 401) {
          this.login();
        } else {
          this.errorOnLoad.nativeElement.innerHTML =
            error.error.errors[0].message;
        }
      }
    );
  }

  loadForm() {
    for (var i = 0; i < this.json.length; i++) {
      if (
        this.json[i].elementType == this.constants.TEXTBOX ||
        this.json[i].elementType == this.constants.SELECTBOX ||
        this.json[i].elementType == this.constants.RADIO
      ) {
        if ("formula" in this.json[i]) {
          this.formulas.push(this.json[i].formula);
        }
      }
    }
    for (var i = 0; i < this.json.length; i++) {
      if (
        this.json[i].elementType == this.constants.TEXTBOX ||
        this.json[i].elementType == this.constants.SELECTBOX ||
        this.json[i].elementType == this.constants.RADIO
      ) {
        let elementOnAction: any = [];
        let action: string = "keyup";

        if (this.json[i].elementType == this.constants.SELECTBOX) {
          elementOnAction = [
            document.getElementById("selectBox-" + this.json[i].element),
          ];
          action = "change";
        } else if (this.json[i].elementType == this.constants.RADIO) {
          let elementOnActiongetIds = document.getElementsByName(
            "name-" + this.json[i].element
          );
          for (let l = 0; l < elementOnActiongetIds.length; l++) {
            elementOnAction.push(
              document.getElementById(elementOnActiongetIds[l].id)
            );
          }
          action = "change";
        } else {
          elementOnAction = [
            document.getElementById("textboxInp-" + this.json[i].element),
          ];
        }

        if (elementOnAction.length > 0) {
          for (let m = 0; m < elementOnAction.length; m++) {
            this.renderer.listen(elementOnAction[m], action, (e) => {
              var getId: any = e.target.id;
              getId = getId.split("-")[1];
              for (var j = 0; j < this.formulas.length; j++) {
                for (var k = 0; k < this.formulas[j].forumlaArr.length; k++) {
                  if (
                    this.formulas[j].forumlaArr[k].name == "id" &&
                    this.formulas[j].forumlaArr[k].id == getId
                  ) {
                    this.formCommonMethods.formularReader(
                      this.formulas[j],
                      this.json
                    );
                  }
                }
              }
            });
          }

          for (var j = 0; j < this.formulas.length; j++) {
            if (this.formulas[j].ans_id == this.json[i].element) {
              var atleastOneField: any = 0;
              for (var k = 0; k < this.formulas[j].forumlaArr.length; k++) {
                if (this.formulas[j].forumlaArr[k].name == "id") {
                  atleastOneField++;
                }
              }
              if (atleastOneField == 0) {
                this.formCommonMethods.formularReader(
                  this.formulas[j],
                  this.json
                );
              }
            }
          }
        }
      }
    }
  }

  ngAfterViewChecked() {
    for (var i = 0; i < this.json.length; i++) {
      for (var j = 0; j < this.formulas.length; j++) {
        if (this.formulas[j].ans_id == this.json[i].element) {
          for (var k = 0; k < this.formulas[j].forumlaArr.length; k++) {
            if (this.formulas[j].forumlaArr[k].name == "id") {
              this.formCommonMethods.formularReader(
                this.formulas[j],
                this.json
              );
            }
          }
        }
      }
    }
  }

  checkConditionsOnSubmit(i: any, k: any): boolean {
    if (this.json[i].applied_conditions[k].action == "HIDE") {
      var index: any = this.formCommonMethods.checkIfInJsonArray(
        this.json,
        this.json[i].applied_conditions[k].if
      );

      let checkCondElem: any = null;
      if (this.json[index].elementType == this.constants.TEXTBOX) {
        let tempElem: any = document.getElementById(
          "textboxInp-" + this.json[index].element
        );
        checkCondElem = tempElem.value.trim();
      } else if (this.json[index].elementType == this.constants.TEXTAREA) {
        let tempElem: any = document.getElementById(
          "textarea-" + this.json[index].element
        );

        checkCondElem = tempElem.value.trim();
      } else if (this.json[index].elementType == this.constants.RADIO) {
        var radio_optns: any = document.getElementsByName(
          "name-" + this.json[index].element
        );

        var radioVal: any = "";
        for (var j = 0; j < radio_optns.length; j++) {
          if (radio_optns[j].checked == true) {
            checkCondElem = radio_optns[j].value;
            break;
          }
        }
      } else if (this.json[index].elementType == this.constants.SELECTBOX) {
        let tempElem: any = document.getElementById(
          "selectBox-" + this.json[index].element
        );
        checkCondElem = tempElem.value.trim();
      } else if (this.json[index].elementType == this.constants.DATESELECTOR) {
        let tempElem: any = document.getElementById(
          "dateInp-" + this.json[index].element
        );

        checkCondElem = tempElem.value.trim();
      }

      var cond = false;
      if (this.json[i].applied_conditions[k].state == "IS EQUAL TO") {
        //cond =   this.json[index]== this.json[i].applied_conditions[k].value

        cond = checkCondElem == this.json[i].applied_conditions[k].value;
      } else if (
        this.json[i].applied_conditions[k].state == "IS NOT EQUAL TO"
      ) {
        //cond =   this.json[index]== this.json[i].applied_conditions[k].value

        cond = checkCondElem != this.json[i].applied_conditions[k].value;
      }
      return cond;
    }
    return false;
  }

  submitForm() {
    var err: boolean = false;

    var finslJson: any = [];
    for (var i = 0; i < this.json.length; i++) {
      let textboxcont: any = document.getElementById(
        "textboxcont-" + this.json[i].element
      );

      var error: any = document.getElementById("error-" + this.json[i].element);
      if (this.json[i].elementType == this.constants.TEXTBOX) {
        var textbox: any = document.getElementById(
          "textboxInp-" + this.json[i].element
        );
        var val: any = textbox.value.trim();
        var elemError: boolean = false;

        if (this.json[i].required != null && this.json[i].required) {
          if (val == "") {
            error.innerHTML = this.json[i].field_label + " is requied";
            elemError = true;
          }
        }

        if (
          this.json[i].validation != "Numeric" &&
          this.json[i].min_char != null &&
          this.json[i].min_char != ""
        ) {
          if (val.length < this.json[i].min_char) {
            error.innerHTML =
              this.json[i].field_label +
              "'s length must be greater or equal to " +
              this.json[i].min_char;
            elemError = true;
          }
        }

        if (
          this.json[i].validation != "Numeric" &&
          this.json[i].max_char != null &&
          this.json[i].max_char != ""
        ) {
          if (val.length > this.json[i].max_char) {
            error.innerHTML =
              this.json[i].field_label +
              "'s length must be less or equal to " +
              this.json[i].max_char;
            elemError = true;
          }
        }

        if (
          this.json[i].validation == "Numeric" &&
          this.json[i].min_range != null &&
          this.json[i].min_range != ""
        ) {
          if (parseInt(val) < parseInt(this.json[i].min_range)) {
            error.innerHTML = "Please enter valid value";
            elemError = true;
          }
        }

        if (
          this.json[i].validation == "Numeric" &&
          this.json[i].max_range != null &&
          this.json[i].max_range != ""
        ) {
          if (parseInt(val) > parseInt(this.json[i].max_range)) {
            error.innerHTML = "Please enter valid value";
            elemError = true;
          }
        }

        if (this.json[i].validataion == this.constants.EMAIL) {
          if (!this.formCommonMethods.checkIfOnlyEmail(val)) {
            error.innerHTML = "Please enter a valid email!";
            elemError = true;
          }
        }

        let fromAppliedConditions: boolean = false;
        if (this.json[i].applied_conditions != undefined) {
          for (let k = 0; k < this.json[i].applied_conditions.length; k++) {
            if (this.checkConditionsOnSubmit(i, k)) {
              elemError = false;
              val = "-";
              fromAppliedConditions = true;
            }
          }
        }

        if (
          this.json[i].hide != undefined &&
          this.json[i].hide == true &&
          textboxcont.classList.contains("d-none-custom-form")
        ) {
          elemError = false;
          val = "-";
          fromAppliedConditions = true;
        }

        if (!elemError) {
          error.innerHTML = "";

          var obj = { element: this.json[i].element, value: val };
          finslJson[finslJson.length] = obj; ////fromAppliedConditions ? "-" : obj;
        } else {
          err = elemError;
        }
      } else if (this.json[i].elementType == this.constants.TEXTAREA) {
        let textboxcont: any = document.getElementById(
          "textboxcont-" + this.json[i].element
        );

        var textarea: any = document.getElementById(
          "textarea-" + this.json[i].element
        );
        var val: any = textarea.value.trim();
        var elemError: boolean = false;

        if (this.json[i].required != null && this.json[i].required) {
          if (val == "") {
            error.innerHTML = this.json[i].field_label + " is requied";
            elemError = true;
          }
        }

        if (this.json[i].min != null && this.json[i].min != "") {
          if (val.length < this.json[i].min) {
            error.innerHTML =
              this.json[i].field_label +
              "'s length must be greater or equal to " +
              this.json[i].min;
            elemError = true;
          }
        }

        if (this.json[i].max != null && this.json[i].max != "") {
          if (val.length > this.json[i].max) {
            error.innerHTML =
              this.json[i].field_label +
              "'s length must be less or equal to " +
              this.json[i].max;
            elemError = true;
          }
        }

        if (this.json[i].validataion == this.constants.EMAIL) {
          if (!this.formCommonMethods.checkIfOnlyEmail(val)) {
            error.innerHTML = "Please enter a valid email!";
            elemError = true;
          }
        }

        let fromAppliedConditions: boolean = false;
        if (this.json[i].applied_conditions != undefined) {
          for (let k = 0; k < this.json[i].applied_conditions.length; k++) {
            if (this.checkConditionsOnSubmit(i, k)) {
              elemError = false;
              val = "-";
              fromAppliedConditions = true;
            }
          }
        }

        if (
          this.json[i].hide != undefined &&
          this.json[i].hide == true &&
          textboxcont.classList.contains("d-none-custom-form")
        ) {
          elemError = false;
          val = "-";
          fromAppliedConditions = true;
        }

        if (!elemError) {
          error.innerHTML = "";
          var obj = { element: this.json[i].element, value: val };
          finslJson[finslJson.length] = obj; //fromAppliedConditions ? "-" : obj;
        } else {
          err = elemError;
        }
      } else if (this.json[i].elementType == this.constants.RADIO) {
        let radiocont: any = document.getElementById(
          "radiocont-" + this.json[i].element
        );

        var radio_optns: any = document.getElementsByName(
          "name-" + this.json[i].element
        );
        var elemError: boolean = false;

        var radioVal: any = "";
        for (var j = 0; j < radio_optns.length; j++) {
          if (radio_optns[j].checked == true) {
            radioVal = radio_optns[j].value;
          }
        }

        if (this.json[i].required != null && this.json[i].required) {
          if (radioVal.trim() == "") {
            error.innerHTML = this.json[i].field_label + " is requied";
            elemError = true;
          } else {
            error.innerHTML = "";
          }
        }

        let fromAppliedConditions: boolean = false;
        if (this.json[i].applied_conditions != undefined) {
          for (let k = 0; k < this.json[i].applied_conditions.length; k++) {
            if (this.checkConditionsOnSubmit(i, k)) {
              elemError = false;
              val = "-";
              fromAppliedConditions = true;
            }
          }
        }

        if (
          this.json[i].hide != undefined &&
          this.json[i].hide == true &&
          radiocont.classList.contains("d-none-custom-form")
        ) {
          elemError = false;
          val = "-";
          fromAppliedConditions = true;
        }

        if (!elemError) {
          var obj = { element: this.json[i].element, value: radioVal };
          finslJson[finslJson.length] = obj; //fromAppliedConditions ? "-" : obj;
        } else {
          err = elemError;
        }
      } else if (this.json[i].elementType == this.constants.SELECTBOX) {
        let textboxcont: any = document.getElementById(
          "textboxcont-" + this.json[i].element
        );

        var selectbox: any = document.getElementById(
          "selectBox-" + this.json[i].element
        );
        var elemError: boolean = false;

        if (this.json[i].required != null && this.json[i].required) {
          if (selectbox.value.trim() == "") {
            error.innerHTML = this.json[i].field_label + " is requied";
            elemError = true;
          } else {
            error.innerHTML = "";
          }
        }

        let fromAppliedConditions: boolean = false;
        if (this.json[i].applied_conditions != undefined) {
          for (let k = 0; k < this.json[i].applied_conditions.length; k++) {
            if (this.checkConditionsOnSubmit(i, k)) {
              elemError = false;
              val = "-";
              fromAppliedConditions = true;
            }
          }
        }

        if (
          this.json[i].hide != undefined &&
          this.json[i].hide == true &&
          textboxcont.classList.contains("d-none-custom-form")
        ) {
          elemError = false;
          val = "-";
          fromAppliedConditions = true;
        }

        if (!elemError) {
          var obj = {
            element: this.json[i].element,
            value: selectbox.value.trim(),
          };
          finslJson[finslJson.length] = obj; //fromAppliedConditions ? "-" : obj;
        } else {
          err = elemError;
        }
      } else if (this.json[i].elementType == this.constants.DATESELECTOR) {
        let datecont: any = document.getElementById(
          "datecont-" + this.json[i].element
        );

        var dateSelector: any = document.getElementById(
          "dateInp-" + this.json[i].element
        );
        let elemError: boolean = false;

        if (this.json[i].required != null && this.json[i].required) {
          if (dateSelector.value.trim() == "") {
            error.innerHTML = this.json[i].field_label + " is requied";
            elemError = true;
          } else {
            error.innerHTML = "";
          }
        }

        if (this.json[i].required != null && this.json[i].required) {
          if (dateSelector.value.trim() == "") {
            error.innerHTML = this.json[i].field_label + " is requied";
            elemError = true;
          }
        }

        let from: Date = new Date(this.json[i].from);
        let to: Date = new Date(this.json[i].to);

        if (this.json[i].from != "" || this.json[i].to != "") {
          let selectedDate: Date = new Date(dateSelector.value.trim());
          if (this.json[i].from != "" && from > selectedDate) {
            error.innerHTML =
              this.json[i].field_label +
              " must be greater than " +
              this.json[i].from;
            elemError = true;
          }
          if (this.json[i].from != "" && to < selectedDate) {
            error.innerHTML =
              this.json[i].field_label +
              "must be smaller than " +
              this.json[i].from;
            elemError = true;
          }
        }

        let fromAppliedConditions: boolean = false;
        if (this.json[i].applied_conditions != undefined) {
          for (let k = 0; k < this.json[i].applied_conditions.length; k++) {
            if (this.checkConditionsOnSubmit(i, k)) {
              elemError = false;
              val = "-";
              fromAppliedConditions = true;
            }
          }
        }

        if (
          this.json[i].hide != undefined &&
          this.json[i].hide == true &&
          datecont.classList.contains("d-none-custom-form")
        ) {
          elemError = false;
          val = "-";
          fromAppliedConditions = true;
        }

        if (!elemError) {
          var obj = {
            element: this.json[i].element,
            value: dateSelector.value.trim(),
          };
          finslJson[finslJson.length] = obj; //fromAppliedConditions ? "-" : obj;
        } else {
          err = elemError;
        }
      } else if (this.json[i].elementType == this.constants.TABLE) {
        let tableCont: any = document.getElementById(
          "table-" + this.json[i].element
        );

        let colCount = parseInt(this.json[i].cols);

        let rowCount = parseInt(this.json[i].rows);
        var objArr = [];

        let elemError: boolean = false;
        for (let j = 0; j < rowCount; j++) {
          var arr = [];
          for (let k = 0; k < colCount; k++) {
            var tableInp: any = document.getElementById(
              "tableInp-" + this.json[i].element + "-" + j + "-" + k
            );

            arr[arr.length] = tableInp.value.trim();
            if (
              this.json[i].required != null &&
              this.json[i].required &&
              tableInp.value.trim() == ""
            ) {
              error.innerHTML = this.json[i].field_label + " is required!";

              elemError = true;
              break;
            }
          }
          objArr[objArr.length] = arr;
        }
        // console.log(objArr);
        // return;
        let fromAppliedConditions: boolean = false;
        if (this.json[i].applied_conditions != undefined) {
          for (let k = 0; k < this.json[i].applied_conditions.length; k++) {
            if (this.checkConditionsOnSubmit(i, k)) {
              elemError = false;
              val = "-";
              fromAppliedConditions = true;
            }
          }
        }

        if (
          this.json[i].hide != undefined &&
          this.json[i].hide == true &&
          tableCont.classList.contains("d-none-custom-form")
        ) {
          elemError = false;
          val = "-";
          fromAppliedConditions = true;
        }

        if (!elemError) {
          error.innerHTML = "";

          var objJson = {
            element: this.json[i].element,
            value: objArr,
          };
          finslJson[finslJson.length] = fromAppliedConditions ? [] : objJson;
        } else {
          err = elemError;
        }
      } else if (this.json[i].elementType == this.constants.ADDRESS) {
        let textboxcont: any = document.getElementById(
          "textboxcont-" + this.json[i].element
        );

        let elemError: any = false;
        let addressInp1: any = document.getElementById(
          "addressInp1-" + this.json[i].element
        );
        let addressInp2: any = document.getElementById(
          "addressInp2-" + this.json[i].element
        );
        let addressStateInp: any = document.getElementById(
          "addressStateInp-" + this.json[i].element
        );
        let addressCityInp: any = document.getElementById(
          "addressCityInp-" + this.json[i].element
        );
        let addressPostalInp: any = document.getElementById(
          "addressPostalInp-" + this.json[i].element
        );
        let addressCountryInp: any = document.getElementById(
          "addressCountryInp-" + this.json[i].element
        );

        if (this.json[i].required != null && this.json[i].required) {
          if (addressInp1.value.trim() == "") {
            error.innerHTML = this.json[i].field_label_address1 + " is requied";
            elemError = true;
          }
          if (addressStateInp.value.trim() == "" && !elemError) {
            error.innerHTML = "In Address State is requied";
            elemError = true;
          }
          if (addressCityInp.value.trim() == "" && !elemError) {
            error.innerHTML = "In Address City is requied";
            elemError = true;
          }
          if (addressPostalInp.value.trim() == "" && !elemError) {
            error.innerHTML = "In Address Postal is requied";
            elemError = true;
          }
          if (addressCountryInp.value.trim() == "" && !elemError) {
            error.innerHTML = "In Address Country is requied";
            elemError = true;
          }
        }

        let fromAppliedConditions: boolean = false;
        if (this.json[i].applied_conditions != undefined) {
          for (let k = 0; k < this.json[i].applied_conditions.length; k++) {
            if (this.checkConditionsOnSubmit(i, k)) {
              elemError = false;
              val = "-";
              fromAppliedConditions = true;
            }
          }
        }

        if (
          this.json[i].hide != undefined &&
          this.json[i].hide == true &&
          textboxcont.classList.contains("d-none-custom-form")
        ) {
          elemError = false;
          val = "-";
          fromAppliedConditions = true;
        }

        if (!elemError) {
          error.innerHTML = "";

          objArr = [
            addressInp1.value.trim(),
            addressInp2.value.trim(),
            addressStateInp.value.trim(),
            addressCityInp.value.trim(),
            addressPostalInp.value.trim(),
            addressCountryInp.value.trim(),
          ];
          objJson = {
            element: this.json[i].element,
            value: objArr,
          };
          finslJson[finslJson.length] = fromAppliedConditions ? [] : objJson;
        } else {
          err = elemError;
        }
      } else if (this.json[i].elementType == this.constants.MULTIPLECHOICES) {
        let multiplechoicescont: any = document.getElementById(
          "multiplechoicescont-" + this.json[i].element
        );

        var multiple_choices_optns: any = document.getElementsByName(
          "name-" + this.json[i].element
        );
        let elemError: boolean = false;

        var multipleChoicesVal: any = [];
        for (var j = 0; j < multiple_choices_optns.length; j++) {
          if (multiple_choices_optns[j].checked == true) {
            multipleChoicesVal[multipleChoicesVal.length] =
              multiple_choices_optns[j].value;
          }
        }

        if (this.json[i].required != null && this.json[i].required) {
          if (multipleChoicesVal.length == 0) {
            error.innerHTML = this.json[i].field_label + " is requied";
            elemError = true;
          }
        }

        let fromAppliedConditions: boolean = false;
        if (this.json[i].applied_conditions != undefined) {
          for (let k = 0; k < this.json[i].applied_conditions.length; k++) {
            if (this.checkConditionsOnSubmit(i, k)) {
              elemError = false;
              val = [];
              fromAppliedConditions = true;
            }
          }
        }

        if (
          this.json[i].hide != undefined &&
          this.json[i].hide == true &&
          multiplechoicescont.classList.contains("d-none-custom-none")
        ) {
          elemError = false;
          val = "-";
          fromAppliedConditions = true;
        }

        if (!elemError) {
          error.innerHTML = "";
          objJson = {
            element: this.json[i].element,
            value: fromAppliedConditions ? [] : multipleChoicesVal,
          };

          finslJson[finslJson.length] = objJson;
        } else {
          err = elemError;
        }
      } else if (this.json[i].elementType == this.constants.USERINFOFIELDS) {
        const regrex = new RegExp(this.constants.numberRegexp);

        let userInfonameInp: any = document.getElementById(
          "userInfonameInp-" + this.json[i].element
        );

        var nameerror: any = document.getElementById(
          "nameerror-" + this.json[i].element
        );
        nameerror.innerHTML = "";

        if (userInfonameInp.value.trim() == "") {
          nameerror.innerHTML = "Name is required!";
          err = true;
        } else {
          this.username = userInfonameInp.value.trim();
        }

        let userInfoemailInp: any = document.getElementById(
          "userInfoemailInp-" + this.json[i].element
        );

        var emailerror: any = document.getElementById(
          "emailerror-" + this.json[i].element
        );
        emailerror.innerHTML = "";

        if (userInfoemailInp.value.trim() == "") {
          emailerror.innerHTML = "Email is required!";
          err = true;
        } else {
          if (
            !this.formCommonMethods.checkIfOnlyEmail(
              userInfoemailInp.value.trim()
            )
          ) {
            emailerror.innerHTML = "Please Enter a valid Email Address!";
            err = true;
          } else {
            this.useremail = userInfoemailInp.value.trim();
          }
        }

        let userInfophoneInp: any = document.getElementById(
          "userInfophoneInp-" + this.json[i].element
        );

        var phoneerror: any = document.getElementById(
          "phoneerror-" + this.json[i].element
        );
        phoneerror.innerHTML = "";

        if (userInfophoneInp.value.trim() == "") {
          phoneerror.innerHTML = "Phone is required!";
          err = true;
        } else {
          if (!regrex.test(userInfophoneInp.value.trim())) {
            phoneerror.innerHTML = "Please Enter a valid Phone No.!";
            err = true;
          } else {
            this.userphone = userInfophoneInp.value.trim();
          }
        }

        if (err) break;
      }
    }

    this.finalJson = finslJson;
    if (!err) {
      //this.showHideOverlayPersonalDetailsForm(1);
      this.finalsubmitFormFunc();
    }
  }

  finalsubmitFormFunc() {
    grecaptcha.ready(() => {
      grecaptcha
        .execute(this.siteConfig.GOOGLE_RECAPTCHA_KEY_SITE, {
          action: "submit",
        })
        .then((token: any) => {
          var submitJson: any = {};

          submitJson.formId = this.formId;
          submitJson.submissionData = JSON.stringify(this.finalJson);
          submitJson.customerName = this.username.trim();
          submitJson.customerEmail = this.useremail.trim();
          submitJson.customerPhone = this.userphone.trim();

          var submitCancelBtn: any = document.getElementById("submitCancelBtn");
          submitCancelBtn.disabled = true;

          var finalsubmitBtn: any = document.getElementById("submitBtn"); //"finalsubmitBtn");
          finalsubmitBtn.innerHTML = "Submitting...";
          finalsubmitBtn.disabled = true;

          var clearBtn: any = document.getElementById("clearBtn");
          clearBtn.disabled = true;

          //return;
          this.formService
            .submitFormData(submitJson, this.formId, token)
            .subscribe(
              (Response: any) => {
                this.showHideOverlayPersonalDetailsForm(0);

                finalsubmitBtn.innerHTML =
                  this.formResponse.paymentOptionUuid == undefined
                    ? "Submit"
                    : "Submit & Pay";
                finalsubmitBtn.disabled = false;

                submitCancelBtn.disabled = false;

                clearBtn.disabled = false;

                var overlayContDiv: any =
                  document.getElementById("overlayContDiv");
                var overlayMainDivSuccessful: any = document.getElementById(
                  "overlayMainDivSuccessful"
                );

                overlayContDiv.classList.add("d-block-custom-form");
                overlayContDiv.classList.remove("d-none-custom-form");

                overlayMainDivSuccessful.classList.add("d-block-custom-form");
                overlayMainDivSuccessful.classList.remove("d-none-custom-form");

                this.redirectingPayment?.nativeElement.classList.add(
                  "d-block-custom-form"
                );
                this.redirectingPayment?.nativeElement.classList.remove(
                  "d-none-custom-form"
                );

                setTimeout(() => {
                  overlayContDiv.classList.add("d-none-custom-form");
                  overlayContDiv.classList.remove("d-block-custom-form");

                  overlayMainDivSuccessful.classList.add("d-none-custom-form");
                  overlayMainDivSuccessful.classList.remove(
                    "d-block-custom-form"
                  );
                  if (
                    this.clientAlias == "DHP" &&
                    this.formId == "b37eff3c-9e88-4b3b-a612-8c3c01bb9bf8"
                  ) {
                    let loc: any =
                      "https://members.madinaapps.com/clients/DHP/memberArea/Programservices/3395";
                    window.location = loc;
                  } else {
                    if (Response != null) {
                      if (
                        "paymentLinkUrl" in Response &&
                        Response.paymentLinkUrl != null
                      ) {
                        window.location = Response.paymentLinkUrl;
                      } else {
                        window.location.reload();
                        this.clearForm();
                      }
                    }
                  }
                }, 3000);
              },
              (error) => {
                this.showHideOverlayPersonalDetailsForm(0);
                var err: any = this.formError;
                err.nativeElement.innerHTML = error.error.errors[0].message;
                finalsubmitBtn.innerHTML =
                  this.formResponse.paymentOptionUuid == undefined
                    ? "Submit"
                    : "Submit & Pay";
                finalsubmitBtn.disabled = false;
                submitCancelBtn.disabled = false;
                clearBtn.disabled = false;
              }
            );
        });
    });
  }

  clearForm() {
    this.finalJson = null;
    for (var i = 0; i < this.json.length; i++) {
      if (this.json[i].elementType == this.constants.TEXTBOX) {
        var textbox: any = document.getElementById(
          "textboxInp-" + this.json[i].element
        );
        textbox.value = "";

        if (this.json[i].default != null && this.json[i].default.trim() != "") {
          textbox.value = this.json[i].default.trim();
        }
      } else if (this.json[i].elementType == this.constants.RADIO) {
        var radio_optns: any = document.getElementsByName(
          "name-" + this.json[i].element
        );
        for (var j = 0; j < radio_optns.length; j++) {
          radio_optns[j].checked = false;

          if (
            this.json[i].default != null &&
            radio_optns[j].value == this.json[i].default
          ) {
            radio_optns[j].checked = true;
          }
        }
      } else if (this.json[i].elementType == this.constants.SELECTBOX) {
        var selectbox: any = document.getElementById(
          "selectBox-" + this.json[i].element
        );
        selectbox.value = "";
        if (this.json[i].default != null && this.json[i].default.trim() != "") {
          selectbox.value = this.json[i].default.trim();
        }
      } else if (this.json[i].elementType == this.constants.TEXTAREA) {
        var textarea: any = document.getElementById(
          "textarea-" + this.json[i].element
        );
        textarea.value = "";

        if (this.json[i].default != null && this.json[i].default.trim() != "") {
          textarea.value = this.json[i].default.trim();
        }
      } else if (this.json[i].elementType == this.constants.DATESELECTOR) {
        var dateInp: any = document.getElementById(
          "dateInp-" + this.json[i].element
        );
        dateInp.value = "";
      } else if (this.json[i].elementType == this.constants.TABLE) {
        let rowCount = document.getElementById(
          "tableInp-" + this.json[i].element
        )!.children.length; //parseInt(this.json[i].rows);
        //parseInt(this.json[i].rows);
        let colCount = parseInt(this.json[i].cols);
        for (let j = 0; j < rowCount; j++) {
          for (let k = 0; k < colCount; k++) {
            var tableInp: any = document.getElementById(
              "tableInp-" + this.json[i].element + "-" + j + "-" + k
            );
            tableInp.value = "";
          }
        }
      } else if (this.json[i].elementType == this.constants.ADDRESS) {
        var addressInp1: any = document.getElementById(
          "addressInp1-" + this.json[i].element
        );
        addressInp1.value = "";
        var addressInp2: any = document.getElementById(
          "addressInp2-" + this.json[i].element
        );
        addressInp2.value = "";
        var addressStateInp: any = document.getElementById(
          "addressStateInp-" + this.json[i].element
        );
        addressStateInp.value = "";
        var addressCityInp: any = document.getElementById(
          "addressCityInp-" + this.json[i].element
        );
        addressCityInp.value = "";

        var addressPostalInp: any = document.getElementById(
          "addressPostalInp-" + this.json[i].element
        );
        addressPostalInp.value = "";

        var addressCountryInp: any = document.getElementById(
          "addressCountryInp-" + this.json[i].element
        );
        addressCountryInp.value = "";
      } else if (this.json[i].elementType == this.constants.USERINFOFIELDS) {
        let userInfonameInp: any = document.getElementById(
          "userInfonameInp-" + this.json[i].element
        );
        userInfonameInp.value = "";
        var nameerror: any = document.getElementById(
          "nameerror-" + this.json[i].element
        );
        nameerror.innerHTML = "";
        let userInfoemailInp: any = document.getElementById(
          "userInfoemailInp-" + this.json[i].element
        );

        userInfoemailInp.value = "";
        var emailerror: any = document.getElementById(
          "emailerror-" + this.json[i].element
        );
        emailerror.innerHTML = "";
        let userInfophoneInp: any = document.getElementById(
          "userInfophoneInp-" + this.json[i].element
        );

        userInfophoneInp.value = "";
        var phoneerror: any = document.getElementById(
          "phoneerror-" + this.json[i].element
        );
        phoneerror.innerHTML = "";
      }
    }
    this.showHideOverlayForm(0, "clear");
  }

  showHideOverlayForm(a: any, which: any) {
    var overlayContDiv: any = document.getElementById("overlayContDiv");
    var overlayMainDiv: any = document.getElementById("overlayMainDiv");

    if (a == 1) {
      overlayContDiv.classList.remove("d-none-custom-form");
      overlayContDiv.classList.add("d-block-custom-form");

      overlayMainDiv.classList.remove("d-none-custom-form");
      overlayMainDiv.classList.add("d-block-custom-form");
    } else {
      overlayContDiv.classList.add("d-none-custom-form");
      overlayContDiv.classList.remove("d-block-custom-form");

      overlayMainDiv.classList.add("d-none-custom-form");
      overlayMainDiv.classList.remove("d-block-custom-form");
    }
  }

  showHideOverlayPersonalDetailsForm(a: any) {
    var overlayContDiv: any = document.getElementById("overlayContDiv");
    var overlayMainDiv: any = document.getElementById(
      "overlayMainDivPersonalDetails"
    );
    if (a == 1) {
      overlayContDiv.classList.remove("d-none-custom-form");
      overlayContDiv.classList.add("d-block-custom-form");

      overlayMainDiv.classList.remove("d-none-custom-form");
      overlayMainDiv.classList.add("d-block-custom-form");
    } else {
      this.finalJson = null;
      overlayContDiv.classList.add("d-none-custom-form");
      overlayContDiv.classList.remove("d-block-custom-form");

      overlayMainDiv.classList.add("d-none-custom-form");
      overlayMainDiv.classList.remove("d-block-custom-form");
    }
  }

  @ViewChild("redirectingPayment") redirectingPayment: ElementRef | undefined;

  changeDynamicValue(elem: any) {
    elem = JSON.parse(elem);
    var index: any = this.formCommonMethods.checkIfInJsonArray(
      this.json,
      parseInt(elem.actionField)
    );

    var selectIndex: any = this.formCommonMethods.checkIfInJsonArray(
      this.json,
      parseInt(elem.indexofelement)
    );

    var rowDiv: any = document.getElementById(
      "selectBox-" + elem.indexofelement
    );

    this.json[index].rows = rowDiv.value;
  }
}
