<div class="elementCont" id="userinfocont-{{ indexofelement }}">
    <div >
     
  
      <div class="mrgt-5">
        <label #userInfoheadingLabel id="userInfoheadingLabel-{{ indexofelement }}" class="txt-bold"></label>

        <div class="flex d-flex-custom-form d-flex-custom-form-768">
            <div class="form-group form-group-custom-for-element w-100-custom-form-768">
                <div class="overflowWrapAnywhere">
                    <label for="" #userInfonameLabel class="inpLabel" id="userInfonameLabel">Name<span style='color:red'>*</span></label>
                  </div>
              
                  <div class="mrgt-5">
                    <input
                      type="text"
                      #userInfonameInp
                      placeholder="Name"
                      id="userInfonameInp-{{ indexofelement }}"
                      (keydown)="keydown($event, indexofelement)"
                      class="form-control form-control-custom-for-element inpText"
                    />
                  </div>
                  <div
                    class="error-msg overflowWrapAnywhere"
                    id="nameerror-{{ indexofelement }}"
                    #nameError
                   ></div>
            </div>
            <div class="mrgl-10 form-group form-group-custom-for-element w-100-custom-form-768">
                <div class="overflowWrapAnywhere">
                    <label for="" #userInfoemailLabel class="inpLabel" id="userInfoemailLabel">Email<span style='color:red'>*</span></label>
                  </div>
              
                  <div class="mrgt-5">
                    <input
                      type="text"
                      #userInfoemailInp
                      placeholder="Email"
                      id="userInfoemailInp-{{ indexofelement }}"
                      (keydown)="keydown($event, indexofelement)"
                      class="form-control form-control-custom-for-element inpText"
                    />
                  </div>
                  <div
                  class="error-msg overflowWrapAnywhere"
                  id="emailerror-{{ indexofelement }}"
                  #emailerror
                 ></div>
            </div>
            <div class="mrgl-10 form-group form-group-custom-for-element w-100-custom-form-768">
                <div class="overflowWrapAnywhere">
                    <label for="" #userInfophoneLabel class="inpLabel" id="userInfophoneLabel">Phone<span style='color:red'>*</span></label>
                  </div>
              
                  <div class="mrgt-5">
                    <input
                      type="text"
                      #userInfophoneInp
                      placeholder="Phone"
                      id="userInfophoneInp-{{ indexofelement }}"
                      (keydown)="keydown($event, indexofelement)"
                      class="form-control form-control-custom-for-element inpText"
                    />
                  </div>
                  <div
                  class="error-msg overflowWrapAnywhere"
                  id="phoneerror-{{ indexofelement }}"
                  #phoneerror
                 ></div>
            </div>
        </div>

      </div>
    </div>
  </div>
 
  