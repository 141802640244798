import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-heading",
  templateUrl: "./heading.component.html",
  styleUrls: ["./heading.component.css", "../../formcommon.css"],
})
export class HeadingComponent {
  @Input() indexofelement: any;
  @Input() formsPositionArray: any;

  public heading: any = "Heading";
  public subHeading: any = "Subheading";

  @ViewChild("headingInp") headingInp!: ElementRef;
  @ViewChild("subheadingInp") subheadingInp!: ElementRef;

  constructor(private formCommonMethods: FormCommonMethodsService) {}

  ngOnInit(): void {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );
      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 100);
    }
  }

  setAttributeChange(data: any) {
    var heading: any = this.headingInp.nativeElement;
    if (heading != null) {
      heading.innerHTML = data?.heading || "";
    }

    var subheading: any = this.subheadingInp.nativeElement;
    if (subheading != null) {
      subheading.innerHTML = data?.subheading || "";
    }

    if (data.hide != undefined && data.hide == true) {
      let textboxcont: any = document.getElementById(
        "textboxcont-" + data.element
      );
      textboxcont.classList.add("d-none-custom-form");
    }
  }
}
