import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormCommonMethodsService } from "src/app/utils/form-common-methods.service";

@Component({
  selector: "app-textarea",
  templateUrl: "./textarea.component.html",
  styleUrls: ["./textarea.component.css", "../../formcommon.css"],
})
export class TextareaComponent {
  @Input() indexofelement: any;
  @Input() formsPositionArray: any;
  public error: any = "";
  @ViewChild("textareaLabel") textareaLabel!: ElementRef;
  @ViewChild("textareaInp") textareaInp!: ElementRef;
  @ViewChild("textareaError") textareaError!: ElementRef;

  constructor(private formCommonMethods: FormCommonMethodsService) {}

  ngOnInit(): void {}

  ngOnChanges(a: any) {
    if ("formsPositionArray" in a || "indexofelement" in a) {
      var found: any = this.formCommonMethods.checkIfInJsonArray(
        this.formsPositionArray,
        this.indexofelement
      );
      setTimeout(() => {
        this.setAttributeChange(this.formsPositionArray[found]);
      }, 50);
    }
  }

  setAttributeChange(data: any) {
    var textareaLabel: any = this.textareaLabel.nativeElement;

    var textareaInp: any = this.textareaInp.nativeElement;
    if (data.field_label != null) {
      textareaLabel.innerHTML = data.field_label;
    }

    if (data.required != null && data.required) {
      textareaLabel.innerHTML += " <span style='color:red'>*</span>";
    } else {
      textareaLabel.innerHTML = data.field_label;
    }

    if (data.placeholder != null && data.placeholder != "") {
      textareaInp.placeholder = data.placeholder;
    } else {
      textareaInp.placeholder = "";
    }

    if (data.default != null && data.default != "") {
      textareaInp.value = data.default;
    } else {
      textareaInp.value = "";
    }

    if (data.readonly != null && data.readonly) {
      textareaInp.setAttribute("readonly", "true");
    } else {
      textareaInp.removeAttribute("readonly");
    }

    if (data.min_char != null && data.min_char != "") {
      textareaInp.setAttribute("minLength", data.min_char);
    } else {
      textareaInp.removeAttribute("minLength");
    }

    if (data.max_char != null && data.max_char != "") {
      textareaInp.setAttribute("maxLength", data.max_char);
    } else {
      textareaInp.removeAttribute("maxLength");
    }

    if (data.validation != null && data.validation != "") {
      textareaInp.setAttribute("validation", data.validation);
    } else {
      textareaInp.removeAttribute("validation");
    }

    if (data.rows != null && data.rows != "") {
      textareaInp.setAttribute("style", "height:unset !important;");

      textareaInp.setAttribute("rows", data.rows);
    } else {
      textareaInp.removeAttribute("rows");
    }

    if (data.columns != null && data.columns != "") {
      textareaInp.setAttribute("style", "height:unset !important;");

      textareaInp.setAttribute("cols", data.columns);
    } else {
      textareaInp.removeAttribute("cols");
    }

    if (data.hide != undefined && data.hide == true) {
      let textareacont: any = document.getElementById(
        "textboxcont-" + data.element
      );
      textareacont.classList.add("d-none-custom-form");
    }
  }

  keydown(event: any, elementId: any) {
    this.error = this.textareaError.nativeElement;

    let index: any = this.formCommonMethods.checkIfInJsonArray(
      this.formsPositionArray,
      elementId
    );
    var char_length = event.target.value.length;

    var finalReturn: boolean = true;

    this.error.innerHTML = "";

    if (
      this.formsPositionArray[index].min_char != null &&
      this.formsPositionArray[index].min_char != ""
    ) {
      if (char_length < this.formsPositionArray[index].min_char) {
        this.error.innerHTML =
          "Char length should be greater than " +
          this.formsPositionArray[index].min_char;
      }
    }

    if (
      this.formsPositionArray[index].max_char != null &&
      this.formsPositionArray[index].max_char != ""
    ) {
      if (char_length >= this.formsPositionArray[index].max_char) {
        this.error.innerHTML =
          "Char length should be less than " +
          this.formsPositionArray[index].max_char;
        finalReturn = false;
      }
    }

    if (
      this.formsPositionArray[index].validation != null &&
      this.formsPositionArray[index].validation != "" &&
      this.formsPositionArray[index].validation.toLowerCase() != "none"
    ) {
      if (
        this.formsPositionArray[index].validation.toLowerCase() == "alphabetic"
      ) {
        finalReturn = this.formCommonMethods.checkIfOnlyCharacter(event);
      } else if (
        this.formsPositionArray[index].validation.toLowerCase() == "numeric"
      ) {
        finalReturn = this.formCommonMethods.checkIfOnlyNumber(event);
      } else if (
        this.formsPositionArray[index].validation.toLowerCase() ==
        "alpha numeric"
      ) {
        finalReturn =
          this.formCommonMethods.checkIfOnlyNumber(event) ||
          this.formCommonMethods.checkIfOnlyCharacter(event);
      } else if (
        this.formsPositionArray[index].validation.toLowerCase() == "email"
      ) {
        var value: any = document.getElementById(event.target.id);
        if (!this.formCommonMethods.checkIfOnlyEmail(value.value)) {
          this.error.innerHTML = "Please enter valid email address!";
        }
      }
      if (event.which == 8 || event.which == 32) {
        finalReturn = true;
      }
    }

    return finalReturn;
  }
}
